.welcome {
    margin-bottom: 1.25rem;
}

.welcome .welcome-message {
    text-align: center;
    margin-bottom: 1.25rem;
    font-size: 0.7rem;
}

.welcome .content {
    text-align: center;
}