.queue-available-message {
    text-align: center;
}

.queue-available-message h3 {
    margin-bottom: 0.6rem;
}

.queue-available-message p {
    color: var(--color-black);
    font-weight: var(--font-family-weight-semi-bold);
    margin-bottom: 0.6rem;
}