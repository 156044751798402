.manage-header {
  background-color: var(--color_primary);
  padding: 20px 35px;
  width: 100vw;
  display: flex;
}
.manage-header p {
  color: var(--color_font);
  margin: 0;
  font-size: 1.4em;
  font-weight: 400;
}

.ham {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 2%;
}

.bar1 {
  width: 30px;
  height: 2.8px;
  background-color: var(--color_font);
  margin: 6px 0;
}

.bar2 {
  width: 21px !important;
  height: 2.8px;
  background-color: var(--color_font);
  margin: 4px 0;
}

.side-nav {
  height: 92.2vh;
  width: 200px;
  position: absolute;
  background-color: white;
  z-index: 999;
  /* background-color: transparent; */
}
.nav-btn {
  display: block;
  text-align: left;
  padding: 20px;
  text-decoration: none;
  color: var(--color_primary) !important;
}
.nav-btn:hover {
  background: #4e4b6613;
}
.nav-btn-active {
  background: #4e4b6624;
  border-left: 4px solid var(--color_primary);
  filter: brightness(1);
}

body {
  overflow-x: hidden;
}

.hide-nav {
  display: none;
}

.dash-container-right {
  margin-left: 200px;
}

.ticket-calling {
  color: green;
  padding: 30px 91px;
  border-radius: 13px;
  border: 2px solid grey;
  margin-bottom: 40px;
  text-align: center;
}
.ticket-calling p {
  margin: 0;
  font-size: 20px;
}
.ticket-calling h1 {
  margin: 0;
  font-size: 3.2em;
  font-weight: 800;
}

.ticket-in-queue {
  color: #6e7191 !important;
  border: 1px solid #ffba0b;
  text-align: center;
}
.ticket-in-queue h1 {
  font-size: 2.5em;
  margin: 10px;
  font-weight: 800;
}

/* td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  } */
/* 
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
 */

table {
  width: 100%;
  border: 1px solid #999;
  text-align: left;
  border-collapse: collapse;
  margin: 0 0 1em 0;
}

td,
th {
  padding: 0.7em;
  border-bottom: 1px solid #999;
  padding-left: 1em !important;
}
th {
  padding: 1.2em;
}

tbody > tr:hover {
  background-color: #f5f5f5;
}

.table-wrap {
  padding: 50px;
}

.queue-top-row {
  display: flex;
  margin-bottom: 1rem;
}
.calling-box {
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px 35px;
  color: green;
  text-align: center;
  width: 12rem;
}
.calling-box h1 {
  font-weight: 800;
  margin: 5px 0 0 0;
}
.queue-call-next {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

hr {
  margin-bottom: 2rem;
}

.btn {
  padding: 0.6rem 3rem;
  font-size: 0.8em;
  border-radius: 60px;
  border: 0;
  cursor: pointer;
}
.btn-primary {
  background-color: var(--color_primary);
  color: var(--color_font);
}
.btn-gray {
  background-color: whitesmoke;
  color:  var(--color_primary);
}


.btn-danger {
  background-color: #f12b2c;
  color: white;
}

.btn-sucess {
  background-color: #00ba88;
  color: white;
}

.btn-sm {
  padding: 0.4rem 1.6rem;
  font-size: 0.9em;
}

tr > td:nth-child(1) > span:nth-child(1) {
  margin-bottom: 7px;
  display: block;
  font-size: 1.2em;
}

tr > td > span:nth-child(2) {
  font-weight: 400;
  color: #595959;
}

.td-name > span:nth-child(1) {
  text-transform: capitalize;
  display: block;
  font-size: 1.2em;
  margin-bottom: 7px;
}

.table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

input[type="time" i] {
  padding-right: 19px !important;
}

.table-header h3 {
  margin: 0;
  font-weight: 500;
}

.table-header {
  padding: 25px 0px;
}

.btn-normal {
  background-color: var(--color_primary);
  border-radius: 6.2px !important;
  padding: 0.6rem 1rem;
  font-size: 1rem;
}

.btn-light {
  background-color: transparent;
  border: 0;
  font-size: 1rem;
  padding: 0;
  padding-left: 20px;
}

.error-message {
  padding: 1px 16px 0 7px;
  color: red;
}

.container-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-box {
  width: 600px;
  box-shadow: 0 1.75rem 2.25rem -0.9375rem rgb(64 64 64 / 9%) !important;
  border: 1px solid#532D6D;
}

.login-box-header {
  background-color: #532d6d;
  color: white;
  padding: 15px;
  text-align: center;
}
.form-container {
  padding: 25px;
}

.btn:disabled {
  cursor: not-allowed;
  background-color: #7c7c7c;
  color: white;
}

.popup-content {
  width: 30% !important;
  padding: 0 !important;
  border: none !important;
  border-radius: 10px;
}

.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  background-color: whitesmoke;
}
.loader-wrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 9999999999;
  background-color: #3535350f;
}

.error-wrapper {
  padding: 10px;
  background-color: #ff0000a6;
  margin: 10px;
  border-radius: 8px;
}
.error-wrapper > p {
  color: white;
  font-style: bold;
}

.duplicate-pop-content {
  width: 20rem !important;
}

.share-pop-content {
  width: 20rem !important;
}

/* share */

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
  border-color: #25d366;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1da851;
  border-color: #1da851;
}

.resp-sharing-button--telegram {
  background-color: #54a9eb;
}

.resp-sharing-button--telegram:hover {
  background-color: #4b97d1;
}

.share-wrapper {
  width: 300px;
  position: relative;
}

.share-input {
  margin: 0px;
  padding: 0px;
  width: 100%;
  outline: none;
  height: 30px;
  border-radius: 5px;
  padding: 16px !important;
  font-size: 18px;
  border: none;
  outline: none;
  padding: 0.9rem 0rem 0.85rem 0rem;
  display: inline-block;
  width: 100%;
  border-radius: 30px;
  border: 2px solid var(--color-border);
}

.share-copy {
  position: absolute;
  top: 0;
  border-radius: 30px;
  right: 0px;
  z-index: 2;
  border: none;
  top: 1px;
  height: 34px;
  cursor: pointer;
  color: white;
  background-color: var(--color_primary);
  transform: translateX(2px);
  padding: 0 20px;
}

.loader-wrapper > div > svg {
  fill: var(--color_primary) !important;
}

.theme-box {
  display: flex;
  justify-content: space-between;
}
.theme-box > .input-box {
  width: 14rem;
  padding: 0 0.7rem !important;
}

.picker-color {
  position: absolute;
  right: 0;
  top: 0.55rem;
  margin-right: 0.5rem;
  border-radius: 60px;
  height: 25px;
  width: 25px;
}

.form-wrapper {
  display: flex;
}
.form-wrapper > .input-box-right {
  width: 350px;
  margin-left: 4rem;
}

.info-txt-box > .input-box {
  height: 8rem;
}

.info-txt-box > .input-box > textarea {
  height: 7.6rem;
  margin: 0px;
  /* width: 300px; */
  resize: none;
  padding-left: 0.4rem;
  font-size: 0.9rem;
  padding-right: 1.6rem;
  line-height: 1.2rem;
}
#store_name {
  font-size: 0.9rem;
}

.footer-fixed {
  margin: auto 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.footer-fixed > img {
  width: 10rem;
}

.footer-float {
  bottom: 0;
  position: fixed;
  width: 100%;
  background-color: white;
}

.footer-float > img {
  width: 12rem;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.display-queue-logo {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}
.display-queue-logo > h2 {
  margin-left: 1rem;
}

.btn-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: flex-end;
  color: var(--color_primary) !important;
}

.phone-form {
  font-size: 1rem !important;
  padding-bottom: 0.7rem !important;
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.space-between {
  justify-content: space-between;
}

.flex-row-between {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.flex-row-between > .info-txt-box {
  width: 50%;
  margin: 0 1rem 0 1rem;
}

.react-tabs__tab {
  width: 33.33%;
  background: #efefef;
  border: 0px !important;
  border-radius: 5px 5px 0 0;
  padding: 16px 18px !important;
  font-size: 1.1rem !important;
  bottom: 0 !important;
}

.react-tabs__tab--selected {
  background: var(--color_primary) !important;
  border-color: transparent;
  color: whitesmoke !important;
  border-radius: 5px 5px 0 0 !important;
}
/* #efefef */
.react-tabs__tab--selected:focus {
  outline: none !important;
}

.react-tabs__tab-list {
  border-bottom: 2.5px solid var(--color_primary) !important;
}

.err-form {
  border: 2px solid #ff3535 !important;
}

.err-message {
  padding: 0.5rem 0;
  font-weight: 600;
  font-style: bold;
  color: red;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}

.link-p {
  color: #0077b5;
  padding: 0.2rem 0;
  cursor: pointer;
}

.table-loading {
  height: 70vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.report-container {
  padding: 1rem 4.5rem;
}

.top-header {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border-radius: 50px;
  border: 1.5px solid var(--color-border);
  padding: 5px 18px;
}

.badge {
  margin-left: 1rem;
  padding: 5px 10px;
  background-color: whitesmoke;
  border-radius: 50px;
  border: 1.5px solid var(--color-border);
  font-size: 0.9rem;
  cursor: pointer;
}
.badge:hover {
  color: whitesmoke;
  border: 1.5px solid var(--color_primary);
  background-color: var(--color_primary);
}

.th-header-small {
  font-weight: 300;
}

.ReactTable .rt-thead {
  overflow-y: scroll;
}

.warning-note {
  width: 95%;
  background-color: #ffcc0059;
  padding: 1rem 1.5rem;
}

.overlay-pop-up {
  height: 70vh !important;
  z-index: 9999 !important;
}
.react-tabs__tab-list {
  margin: 0 !important;
}

table {
  width: 100%;
  border: 2px solid #999;
  text-align: left;
  border-collapse: collapse;
  margin: 0 0 1em 0;
}
.lndscp-table {
  background-color: white !important;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}
.lndscp-table td,
.lndscp-table th {
  padding: 10.2px;
  border: 0.5px solid #999;
  /* padding-left: 1em !important; */
}
.lndscp-table td,
.portrait-table td {
  padding: 14.1px 0;
  /*padding: 6.2px 0;*/
  text-align: left;
}
.lndscp-table tr {
  height: auto;
}
.border-none {
  border: none !important;
}
.bordertop-none {
  border-top: none !important;
  border-right: none !important;
}

.lndscp-table {
  background-color: black;
  text-align: center;
  margin: 0px 0px 0px 0px;
  color: black;
  /* border: 0.5px solid #999; */
  border: 0;
}
.lndscp-table .title {
  font-size: 26px;
}
.lndscp-table .calling,
.portrait-table .calling {
  background-color: rgb(73, 197, 127);
}
/* .lndscp-table .queue,
.portrait-table .queue {
} */
.white-text {
  padding-left: 0px !important;
  font-size: xx-large;
  color: black;
  font-weight: bolder;
  text-align: center !important;
  border-left: 0px solid red !important;
}

.lndscp-table .green-text,
.portrait-table .green-text {
  padding-left: 0px !important;
  font-size: xx-large;
  color: #2eb35f;
  font-weight: bolder;
  text-align: center;
}
.portrait-table {
  width: 100%;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  border: none;
  margin: 0 0 0 0;
  background-color: white;
  color: black;
}
.portrait-table .title {
  font-size: 24px;
}
.portrait-table td {
  padding: 3px 0px;
  border: 0.5px solid #999;
  /* padding-left: 1em !important; */
}
.portrait-table th {
  padding: 3px 0px;
  border: none;
  border-top: 0.25px solid #999;
  border-bottom: 0.25px solid #999;
  /* padding-left: 1em !important; */
}
.portrait-table td {
  width: 30px;
}

/* table */
.p-0 {
  padding: 0 !important;
}

.td-table > tr {
  height: auto !important;
}

.table-landscape-body > tr > td {
  padding: 1rem !important;
}

.badge-ticket {
  font-size: large !important;
  display: inline-block !important;
  margin-left: 1rem !important;
  margin: 0px;
  background-color: var(--color_primary);
  padding: 0.1rem 1rem;
  border-radius: 60px;
}

/* carousel */

.slick-track {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.slick-slide > div > div {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
}

.btn-rounded {
  padding: 5px 7px;
  border-radius: 60px;
  border: 0;
  cursor: pointer;
}

.btn-rounded:hover {
  background-color: red;
  color: whitesmoke;
}

.resolution-info {
  /* display: none; */
  color: whitesmoke;
  position: absolute;
}

.promotion-side {
  width: 60%;
  background-color: #000;
  min-height: inherit;
}

.w-20 {
  width: 30rem;
}