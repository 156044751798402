.input-box {
  border: 2px solid var(--color-border);
  border-radius: 30px;
  padding-left: 1.25rem;
  position: relative;
  margin-bottom: 1.6rem;
}

.input-box label {
  text-transform: uppercase;
  color: var(--color_primary);
  position: absolute;
  top: -9px;
  left: 20px;
  padding: 0rem 0.3rem;
  background-color: var(--color-white);
  font-size: 0.65rem;
  font-weight: var(--font-family-weight-bold);
}

.input-box label span {
  color: red;
  padding-left: 0.3rem;
}

.input-box .name-input {
  border: none;
  outline: none;
  padding: 0.9rem 0rem 0.85rem 0rem;
  display: inline-block;
  width: 100%;
  border-radius: 30px;
}

::-webkit-input-placeholder {
  color: var(--color-placeholder);
}

::-moz-placeholder {
  color: var(--color-placeholder);
}

::-ms-placeholder {
  color: var(--color-placeholder);
}

::placeholder {
  color: var(--color-placeholder);
}
