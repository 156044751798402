@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color_primary: #f99026;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-border: #e5e5e5;
  --color-success: #00966d;
  --color-active: #14142b;
  --color-greyscale: #6e7191;
  --color-checkbox: #d9dbe9;
  --color-queue-card: #4d4d4d;
  --color-placeholder: #9fa6ae;
  --color_font: #ffffff;

  --font-family-weight-regular: 400;
  --font-family-weight-semi-bold: 600;
  --font-family-weight-bold: 700;
  --font-family-weight-bolder: 800;

  --font-family-Nunito: "Nunito Sans", sans-serif;
  --font-family-Poppins: "Poppins", sans-serif;
  font-family: "Poppins", sans-serif;
}

.ticket-box {
  padding: 15px;
  border: 1px solid grey;
  width: 150px;
  text-align: center;
  margin-top: 20px;
}
.ticket-box-sucess {
  border: 1px solid green !important;
  color: green !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 450px;
  }
}

.button {
  background-color: var(--color_primary);
  border-radius: 26px;
  display: inline-block;
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--color_font);
  border: none;
  padding: 8px 0px;
  cursor: pointer;
  font-weight: 600;
}

.network-status {
  display: flex;
  background-color: #ff0000ad;
  justify-content: center;
  color: white;
}

.overlay {
  height: 100vh;
  width: 100%;
  background: rgba(200, 200, 200, 0.5);
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
}
.modal > div {
  border-radius: 10px;
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.login-background {
  background: #f2f2f2 url("../images/background.png");
  background-size: cover;
}


.text-primary-title {
  color: var(--color_primary);
  font-weight: 600;
}