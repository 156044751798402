.cards {
  margin: 1.25rem 0rem;
  display: flex;
  flex-basis: 30%;
  justify-content: space-around;
}

.input-boxes {
  margin: 1.6rem 0rem;
}

.message-checkbox {
  margin-bottom: 1.6rem;
}

.safety-message {
  color: #9d9d9d;
  margin: 1.25rem 0rem;
  font-size: 0.65rem;
}

.queue-button {
  margin-bottom: 0.25rem;
}

/* .tickets-ul > li {
  margin-right: 1rem;
}
.tickets-ul > li:nth-last-child(1) {
  margin-right: 0rem;
} */

.queue-wrapper {
  width: 7rem;
  padding: 0.8rem 0.4rem;
}

.tick-card-carousel > ul > li {
  width: auto !important;
}
.card-carousel-center {
  justify-content: center !important;
}
