.queue-card {
    border: 1px solid var(--color-border);
    border-radius: 4px;
    padding: 0.3rem 1rem;
    text-align: center;
    font-size: 1em;
}

.now-calling {
    color: var(--color-success);
    font-family: var(--font-family-Nunito);
    margin-bottom: 0.4rem;
}

.now-calling p:nth-child(1) {
    font-weight: var(--font-family-weight-semi-bold);
    margin-bottom: 0.3rem;
    font-size: 0.6em;
}

.now-calling p:nth-child(2) {
    font-size: 1.4em;
    font-weight: var(--font-family-weight-bolder);
}

.next-available {
    font-family: var(--font-family-Nunito);
    margin-bottom: 0.4rem;
}

.next-available p:nth-child(1) {
    margin: 0.3rem 0rem;
    color: var(--color-greyscale);
    font-size: 0.6em;
}

.next-available p:nth-child(2) {
    font-size: 1.4em;
    font-weight: var(--font-family-weight-bolder);
    color: var(--color-active);
}

.no-of-guests {
    margin-top: 0.3rem;
    color: var(--color-greyscale);
}

.no-of-guests p {
    font-size: 0.7em;
}