.react-tel-input {
  padding: 0.3rem 0rem 0.2rem 0rem;
}

.react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: none;
}

input[type="tel"] {
  display: inline-block;
  width: 100%;
  background: transparent !important;
  padding: 15px 0px;
}

.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: transparent !important;
  outline: none !important;
}
