.queue-ticket-time-call {
    margin-bottom: 20px;
}

.queue-ticket-time-call > p {
    color: var(--color-queue-card);
    text-align: center;
    font-family: var(--font-family-Poppins);
    font-weight: var(--font-family-weight-bold);
    margin-bottom: 25px;
}

.queue-card-current-call {
    margin: 0 auto;
    border: 1px solid var(--color-black);
    border-radius: 4px;
    max-width: 180px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.queue-card-current-call p {
    color: var(--color-success);
}

.queue-card-current-call p:nth-child(1) {
    margin-bottom: 10px;
}

.queue-card-current-call p:nth-child(2) {
    margin-bottom: 10px;
    font-family: var(--font-family-Nunito);
    font-weight: var(--font-family-weight-bold);
    font-size: 24px;
}