.check-box {
  display: flex;
  align-items: center;
}

.round {
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.round label {
  background-color: var(--color-checkbox);
  border: 1px solid var(--color-checkbox);
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label::after {
  border: 2px solid var(--color-white);
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 6px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--color_primary);
  border-color: var(--color_primary);
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.chechbox-name {
  padding-left: 25px;
  font-size: 0.8rem;
  position: relative;
  z-index: -100;
  top: 2px;
}
