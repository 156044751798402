.queue-card-rectangle {
    overflow: hidden;
    height: 100px;
    max-width: 165px;
    margin: 0px auto;
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
  }
  
  .queue-card-semicircle {
    border: 5px solid var(--color-queue-card);
    border-radius: 10px;
    height: 100px;
    max-width: 180px;
    position: relative;
    box-sizing: border-box;
  }
  
  .queue-card-semicircle::before,
  .queue-card-semicircle::after {
    content: '';
    width: 35px;
    height: 30px;
    border: 5px solid var(--color-queue-card);
    background: var(--color-white);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);
    z-index: 2;
  }
  
  .queue-card-semicircle::after {
    left: auto;
    right: -30px;
  }

  .queue-card-dotted-line {
    position: absolute;
    border-left: 4px dotted var(--color-queue-card);
    left: 25%;
    top: 0;
    bottom: 0;
  }

  .queue-card-content {
      position: absolute;
      left: 35%;
      top: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
  }

  .queue-card-content p {
      color: var(--color-queue-card);
      font-family: var(--font-family-Poppins);
  }

  .queue-card-content p:nth-child(1) {
      font-size: 24px;
      font-weight: var(--font-family-weight-bold);
  }

  .queue-card-content p:nth-child(2) {
      font-size: 18px;
      font-weight: var(--font-family-weight-semi-bold);
  }